import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';

export const loadingQuizzesSelector = (state) => state.quiz.loadingQuizzes;

export const quizzesSelector = (state) => state.quiz.quizzes;

const getDuration = (item) => moment.duration(moment(item.time_end).diff(moment(item.time_start))).seconds();

const getResult = (item) => {
  if (item.questions && item.questions.length > 0) {
    let count = 0;
    const points = _.reduce(
      item.questions,
      (result, q) => {
        const answered = _.find(q.answers, { user_answered: true });
        if (answered) {
          count += 1;
        }
        const point = answered && answered.is_correct ? 1 : 0;
        return result + point;
      },
      0
    );
    return `${((points * 100) / count).toFixed(0)}%`;
  }
  return 0;
};

export const shortCourseQuizzesSelector = createSelector(
  quizzesSelector,
  (state) => state.point.period,
  (quizzes, period) =>
    quizzes
      ? _.chain(quizzes)
          .filter(
            (item) =>
              item.short_course_id &&
              moment(item.time_end).isBefore(period.end) &&
              moment(item.time_start).isAfter(period.start)
          )
          .map((item) => ({
            ...item,
            shortCourseName: item.course ? item.course.name : '',
            duration: getDuration(item),
            date: moment(item.time_end).format('DD/MM/YYYY'),
            result: getResult(item),
          }))
          .value()
      : undefined
);

export const industryQuizzesSelector = createSelector(
  quizzesSelector,
  (state) => state.point.period,
  (quizzes, period) =>
    quizzes
      ? _.chain(quizzes)
          .filter(
            (item) =>
              item.industry_id &&
              moment(item.time_end).isBefore(period.end) &&
              moment(item.time_start).isAfter(period.start)
          )
          .uniqBy('industry_id')
          .map((item) => ({
            ...item,
            industryName: item.industry ? item.industry.name : '',
            industryCode: item.industry ? item.industry.code : '',
            duration: getDuration(item),
            result: getResult(item),
          }))
          .value()
      : undefined
);

export const loadingQuizQuestionsSelector = (state) => state.quiz.loadingQuizQuestions;

const quizSelector = (state) => state.quiz.quiz;

export const quizIdSelector = createSelector(quizSelector, (quiz) => (quiz ? quiz.quiz_id : null));

export const quizQuestionsSelector = createSelector(quizSelector, (quiz) => (quiz ? quiz.questions : undefined));

export const loadingQuizAnswersSelector = (state) => state.quiz.loadingQuizAnswers;

export const quizAnswersSelector = createSelector(
  (state) => state.quiz.answer,
  (answer) => (answer ? answer.questions : undefined)
);
